import React, { useState } from 'react';
import PropTypes from 'prop-types';

Search.propTypes = {
    handler_Search: PropTypes.func,
};

Search.defaultProps = {
    handler_Search: null,
};

function Search(props) {
    const { handler_Search } = props;
    const [search, set_search] = useState('');

    const onChangeText = (e) => {
        set_search(e.target.value); // Update the search input value
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form behavior (e.g., page reload)
        if (handler_Search) {
            handler_Search(search); // Trigger the search handler
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input 
                type="text" 
                className="li-search-field" 
                placeholder="Search here" 
                value={search} 
                onChange={onChangeText} 
            />
            <button type="submit" className="li-search-btn">
                <i className="fa fa-search"></i>
            </button>
        </form>
    );
}

export default Search;

